<template>
	<div 
		class="w-full md:w-150 overflow-hidden h-full flex flex-col fixed md:static inset-0 bg-white"
		style="z-index: 1000;">
		
		<div class="flex-none bg-beige-dark flex flex-col h-32">
			<div class="flex-grow flex px-4 pt-4">
				<div 
					class="flex-none w-12 h-12 flex items-center justify-center"
					@click="$emit('setShowKudosGive', false)">
					
					<i class="fas fa-times text-black text-xl" />
				</div>
				
				<div class="flex-grow flex items-center justify-center">
					<h1>Live aktivitet</h1>
				</div>
				<div class="flex-none w-12 h-12 flex items-center justify-center">
					
				</div>
			</div>
		</div>
		
		<div 
			class="flex-grow overflow-hidden flex items-center justify-center"
			v-if="loading && loading == 'fetching'">
			
			<div 
				class="spinner mx-auto"
			/>
		</div>
		
		<div 
			v-if="!loading && departments && users"
			class="flex-grow overflow-hidden ">
			
			<TransactionsListForKudosGiving 
				:departments="departments"
				:user="user"
				:users="users"
			/>
		</div>
		
	</div>
</template>

<script>
export default {
	components: {
		'TransactionsListForKudosGiving': () => import('@/components/transactions/TransactionsListForKudosGiving.vue'),
	},
	
	computed:{
		departments(){
			return this.$store.getters.getDepartments;
		},
		
		users(){
			return this.$store.getters.getUsers;
		},
		
		user(){
			if (this.signedInUser) {
				return this.signedInUser;
			}
			
			return null;
			/* else if (!this.users) {
				return null;
			} */
			
			// return this.users.find( u => u.username == this.username);
		},
		
	},
	
	/* methods: {
		onShowKudosGiveToggle(){
			this.$store.commit('setShowKudosGive', false);
		},
	}, */
	
	data(){
		return {
			loading: null,
		}
	},
	
	async mounted(){
		console.log('mounted kudosgive');
		
		if (this.account.accessToken) {
			// already loaded..
		}
		else {
			this.loading = 'fetching';
			await Promise.allSettled([
				this.$store.dispatch('listUsers'),
				this.$store.dispatch('listDepartments'),
			]);
			this.loading = null;
			
			console.log('mounted kudosgive DONE!');
		}
	}
}
</script>